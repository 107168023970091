.card {
  position: relative;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px #d1d1d1;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    .card-hover-content {
      bottom: 50%;
      opacity: 1;
      transform: translateY(60%);
      height: 60%;
      background-color: white;
      width: 80%;
      margin-left: 10%;
      border-radius: 10px;
      color: black;
    }
  }

  .card-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }

  .card-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(74, 74, 74, 0.1));
    z-index: 1;
  }

  .card-title {
    position: absolute;
    bottom: 10px;
    left: 20px;
    z-index: 2;
    color: white;
    font-size: 18px;
    font-weight: bold;
  }

  .card-hover-content {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(252, 187, 88, 0.8), rgba(252, 187, 88, 0.1));
    color: white;
    text-align: center;
    padding: 15px;
    transition: all 0.4s ease;
    opacity: 0;
    z-index: 2;
  }

  .service-chip {
    background: #ffffff;
    border-radius: 15px;
    color: #ff9a00;
    text-align: center;
    padding: 3px;
    margin-bottom: 0.5rem;
  }
}