.client-card {
    background: #424242;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    max-width: 350px;
    margin: 20px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 40px rgba(0, 0, 0, 0.5);
    }
  
    .image-container {
      width: 100%;
      height: 200px;
      overflow: hidden;
  
      .client-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s;
  
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  
    .card-content {
      padding: 15px;
      text-align: center;
      color: #ffffff;
  
      h4 {
        margin-top: 10px 0;
        font-size: 1.5rem;
      }
  
      .visit-link {
        display: inline-block;
        margin-top: 10px;
        padding: 10px 15px;
        background-color: #FCBB58;
        color: black;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: white;
        }
      }
    }
  }
  