@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Manrope:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
}

// scss for navhomsearch section
.homeBg {
    position: absolute;
    margin: 0px auto;
    width: 100%;
    //width: 1439px;
    height: 571px;
    object-fit: cover;
    z-index: -80;
    transition: opacity 1s ease-in-out;

    @media (max-width:900px) {
        object-fit: cover;
        height: 400px;
        //transform: translate(-15%,0%);
    }

    @media (max-width:600px) {
        height: 225.5px;
    }
}

.navHomeContainer {
    // techYatra
    width: 100%;
    overflow: hidden;
    //height: 100vh;
    min-height: 80vh;

    @media (max-width:900px) {
        //height: 30vh;
        min-height: 390px;
    }

    @media (max-width:600px) {
        min-height: 225.5px;
    }

    .navHomeBodyContainer {
        margin-top: 25vh;
        position: absolute;
        left: 2vw;

        @media (max-width:600px) {
            right: 0vw;
        }

        .bodyTitle {
            width: 50rem;
            height: auto;
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 3.0625rem;
            color: #000000;

            @media (max-width:900px) {
                width: 28.6875rem;
                height: auto;
                font-size: 1.2rem;
                line-height: 126.7%;
            }

            @media (max-width:600px) {
                width: 19rem;
                font-size: 1rem;
                line-height: 1.7rem;
                margin-top: 6rem;
            }
        }

        .bodyDescr {
            height: 3.4375rem;
            width: 40vw;
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 169.2%;
            color: #091638;

            @media (max-width:900px) {
                width: 19.6875rem;
                margin-top: 55px;
                font-size: 1rem;
                line-height: 1.4rem;
            }

            @media (max-width:600px) {
                margin-top: 10px;
                width: 13rem;
                font-size: 0.7rem;
                line-height: 1rem;
            }
        }
    }

    .navHomeSearchContainer {}

}


// top offers section
#topOffersContainer {
    width: 90%;
    margin: 0px auto;
    margin-top: 4rem;

    @media (max-width:600px) {
        margin-top: 4rem;
    }

    .topOffersItem1 {
        padding-top: 1.5rem;

        .topOffersTitle {
            font-style: normal;
            font-weight: 400;
            font-size: 2.5rem;
            line-height: 4.3125rem;
            color: #121212;

            @media (max-width:900px) {
                font-size: 2.4rem;
                line-height: 3rem;
            }

            @media (max-width:600px) {
                font-size: 2rem;
                line-height: 1.5625rem;
            }
        }
    }

    .topOffersItem2 {
        width: 90%;
        padding-top: 2.5rem;

        .topOffersDescr {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 1.3129rem;
            line-height: 1.75rem;
            font-feature-settings: 'liga'off;
            color: #091638;

            @media (max-width:900px) {
                width: 80%;
                font-size: 1.2rem;
            }

            @media (max-width:600px) {
                width: 20rem;
                font-size: 0.92375em;
            }
        }

        .topOffersButton {
            border: 0.125rem solid #1C3988;
            border-radius: 0.25rem;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.25rem;
            text-align: center;
            color: #1C3988;
            height: 3rem;

            @media (max-width:900px) {
                margin-top: 0.625rem;
            }
        }
    }

    .topOffersItem3 {
        width: 90%;
        padding-top: 1.5rem;

        .progressBarContainer {
            width: 80%;
            height: 0.125rem;
            background: #adbde8;

            .progressBar {
                background: #1C3988;
                height: 0.125rem;
                width: 0.001%;
            }
        }

        .leftRightBtnContainer {
            width: 20%;
            flex-wrap: nowrap;

            .leftBtn {
                cursor: pointer;

                // &:hover{ } for hover
                @media (max-width:900px) {
                    width: 2rem;
                    height: 2rem;
                }
            }

            .rightBtn {
                cursor: pointer;

                // &:hover{} for hover
                @media (max-width:900px) {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }

    .topOffersItem4 {
        padding-top: 1.5rem;
        flex-wrap: nowrap;
        width: 98%;

        .manyCardsContainer {
            display: flex;
            justify-content: space-between;
            overflow-x: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .cardSlideContainer {
                background: #d3e7f5;
                border: 1px solid #F3F3FA;
                border-radius: 20px;
                margin-right: 2.5%;
                margin-left: 2.5%;
                margin-bottom: 1.875rem;

                .cardContainer {
                    height: 28rem;
                    width: 25vw;
                    border-radius: 10px;
                    min-width: 15rem;

                    @media only screen and (max-width: 600px) {
                        height: auto;
                    }

                    .cardImg {
                        width: 70%;
                        height: auto;
                        object-fit: fill;
                        margin: 15%;
                        margin-top: 2%;
                    }

                    .cardContentContainer {
                        width: 100%;
                        margin-top: -2rem;

                        .cardDescr {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 1.2rem;
                            line-height: 1rem;
                            color: #121212;
                        }

                        .cardCost {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 1.0625rem;
                            line-height: 1.4375rem;
                            color: #1C3988;
                            padding-top: 15px;
                        }

                        .cardLoc {
                            font-family: 'Segoe UI';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1rem;
                            line-height: 1.4375rem;
                            color: #121212;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}


// featured list section
.featuredListContainer {
    width: 100%;
    margin: 0 auto;
    margin-top: 5rem;

    @media (max-width:900px) {
        width: 100%;
    }

    .featuredListingTitle {
        margin-bottom: 35px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 3.4375rem;
        text-align: center;
        color: #000000;

        @media (max-width:900px) {
            font-size: 2.2rem;
            line-height: 2.4rem;
        }

        @media (max-width:600px) {
            font-size: 1.3125rem;
            line-height: 1.8125rem;
        }
    }

    //container for left n right containers
    .featuredListBox {

        //    background: #E0ECFD;
        @media (max-width:900px) {
            padding: 0 5%;
        }

        //left container
        .leftContainer {
            position: relative;

            .topImg {
                top: 30px;
                width: 50vw;
                margin: 0 auto;
                display: block;
                position: relative;
                object-fit: contain;

                @media (max-width:900px) {
                    width: 100%;
                    height: 50%;
                    left: -0.1rem;
                    top: 4.0625rem;
                    object-fit: contain;
                }
            }

            .bottomImg {
                display: block;
                height: 90%;
                position: absolute;
                left: 20%;
                top: 12.5rem;
                object-fit: contain;
                box-shadow: 0 30px 40px rgba(0, 0, 0, .1);

                //    filter: drop-shadow(0px 163px 80px rgba(0, 0, 0, 0.04)) drop-shadow(0px 105.648px 46.8519px rgba(0, 0, 0, 0.0303704)) drop-shadow(0px 62.7852px 25.4815px rgba(0, 0, 0, 0.0242963)) drop-shadow(0px 32.6px 13px rgba(0, 0, 0, 0.02)) drop-shadow(0px 13.2815px 6.51852px rgba(0, 0, 0, 0.0157037)) drop-shadow(0px 3.01852px 3.14815px rgba(0, 0, 0, 0.00962963)) drop-shadow(48px 58px 44px rgba(0, 0, 0, 0.09));
                @media (max-width:900px) {
                    left: 16%;
                }
            }
        }

        //right container
        .rightContainer {
            padding: 30px 0 30px 70px;

            @media (max-width:900px) {
                padding-left: 0;
                margin-top: 60px;
            }

            .rightContainerTitle {
                font-family: 'Segoe UI';
                font-style: normal;
                font-weight: 600;
                font-size: 1.9375rem;
                line-height: 2.625rem;
                color: #000000;

                @media (max-width:900px) {
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    margin: 0px auto;
                    text-align: center;
                }

                @media (max-width:600px) {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }

            }

            .rightContainerDescr {
                width: 100%;
                font-family: 'Segoe UI';
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.3rem;
                color: #1b1b1b;
                margin-bottom: 1rem;

                @media (max-width:900px) {

                    font-size: 1.2rem;
                    line-height: 1.8rem;
                }

                @media (max-width:600px) {

                    font-size: 1.2rem;
                    line-height: 1.8rem;
                }

            }

            .whyLearnDesc {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .rightContainerFeatures {
                width: 95%;
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 700;
                font-size: 1.0625rem;
                line-height: 1.5375rem;
                color: #000000;

                @media (max-width:900px) {
                    font-size: 1.0625rem;
                    line-height: 1.4375rem;
                }
            }

            .rightContainerFeatureValues {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                font-size: 1.0625rem;
                line-height: 1.4375rem;
                color: #545A58;

                @media (max-width:900px) {
                    font-size: 1.0625rem;
                    line-height: 1.5375rem;
                }
            }

            #rightContainerButton {
                padding: 0 15px 0 15px;
                width: 100%;
                height: 3.3125rem;
                border: none;
                background: #F85A47;
                box-shadow: 0px 100px 80px rgba(248, 90, 71, 0.07), 0px 64.8148px 46.8519px rgba(248, 90, 71, 0.0531481), 0px 38.5185px 25.4815px rgba(248, 90, 71, 0.0425185), 0px 20px 13px rgba(248, 90, 71, 0.035), 0px 8.14815px 6.51852px rgba(248, 90, 71, 0.0274815), 0px 1.85185px 3.14815px rgba(248, 90, 71, 0.0168519);
                border-radius: 0.3125rem;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 500;
                font-size: 1.125rem;
                line-height: 1.375rem;
                text-align: center;
                color: #FFFFFF;
                cursor: pointer;

                @media (max-width:900px) {
                    width: 100%;
                    margin: 0 auto;
                    height: 3.3125rem;
                }

            }
        }
    }
}

.sq_header {
    text-align: left;
    font-family: Arial;
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
}