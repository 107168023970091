$primary-color: #121212;
$secondary-color:#ffffff;
@mixin fonts($family,$style,$weight,$size,$lineHeight){
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    font-size: $size;
    line-height: $lineHeight;
}

.root {
    flex-grow: 1;
    height: 55px;
    background-color: $primary-color !important;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: left;
    background-color:transparent;

    .navbar-logo{
        margin-left:10px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .navbarLogo1{
        width:52.54px;
        padding-right: 10px;
    }

    .navbarLogo1-small{
        width:12vw;
    }

    .navbarLogo2{
        width:12vw;
    }

    .navbarLogo2-small{
        width:97.18px;
        padding-bottom:8px;
    }

    .menu {
        flex-grow: 1;
        display: flex;
        color:black;
        margin-left: 30px;
    }

    .navButton-right{
        margin-left: auto;
        margin-right: 2%;
    }

    .navbarButton {
        margin-left: auto;
        cursor: pointer;

        .navbarcontactusButton{
            cursor: pointer;
            width: 109px;
            height: 40px;
            background: $secondary-color;
            border:none;
            border-radius: 5px;
            @include fonts('Roboto',normal,400,17px,21px);
            color: $primary-color !important;
            text-align:center;
            box-shadow: 0px 2px 4px rgb(77, 105, 147);
        }
    }
}

.menuName{
    @include fonts('Roboto',normal,800,12px,26px);
    cursor:pointer;
    padding-left:10px;
    padding-right:10px;
    margin-left: 5px;
    color: #ffffff;
}

.activeItem {
    border-bottom-width: 1;
    border-bottom-style: solid;
    border-bottom-color: $secondary-color;
}


.drawerContainer{
    width: 70%
}

.drawer{
    .navbar-logo-drawer{
        height:40px;
        margin-bottom:16px;

        .menuButton-drawer{
            width:20px;
            margin-left:20px;
            margin-bottom:10px;
            margin-right:5px;
        }

        .navbarLogo1-small{
            width:12vw;
        }

        .navbarLogo2-small{
            width:97.18px;
            padding-bottom:10px;
        }
    }
}
.menuItem-drawer{
    border-radius: 8px;
    height:36px;
    padding:8px;
    cursor:pointer;
    display:flex;
    align-items: center;

    .menuIcon-drawer{
        margin-left:16px;
        margin-right:10px;
        width:20px;
        height:20px;
    }

    .menuName-drawer{
        height: 20px;
        @include fonts('SF Pro Display',normal,400,15px,20px);
        color: #1B1B1A;
    }
}

.lastItem-drawer{
    margin-top:10px;
}

.sq_header {
    text-align: left;
    font-family: Arial;
    letter-spacing: 0px;
    opacity: 1;
    display: flex;
    flex-direction: row;
}
