@import './variables.scss';

.howitworksContainer {
    width: 90%;
    margin: $default-margin;
    margin-top: 5rem;

    .devPaths {
        @media only screen and (max-width: 600px) {
            display: flex;
            justify-content: center;
        }
    }

    @media only screen and (max-width: 960px) {
        width: 100%;
    }

    .howitworks-heading {
        margin: $default-margin;

        .howitworks-text {
            width: 262px;
            @include fonts('Manrope', normal, 600, 40px, 55px);
            color: $default-color;
            text-align: center;

            @media only screen and (max-width: 960px) {
                width: 131px;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    .howitworksCards {
        padding-top: 70px;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .howItWorksElement {
        justify-content: center;
        display: flex;
    }
}

.cardSlideContainer {
    background: #d3e7f5;
    border: 1px solid #F3F3FA;
    border-radius: 20px;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-bottom: 1.875rem;

    .cardContainer {
        height: auto;
        width: 25vw;
        border-radius: 10px;
        min-width: 15rem;

        .cardImg {
            width: 70%;
            height: auto;
            object-fit: fill;
            margin: 15%;
            margin-top: 2%;
        }

        .cardContentContainer {
            width: 100%;
            margin-top: -2rem;

            .cardDescr {
                font-style: normal;
                font-weight: 700;
                font-size: 1.2rem;
                line-height: 1rem;
                color: #121212;
            }

            .cardCost {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 1.0625rem;
                line-height: 1.4375rem;
                color: #1C3988;
                padding-top: 15px;
            }

            .cardLoc {
                font-family: 'Segoe UI';
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.4375rem;
                color: #121212;
                text-align: center;
            }
        }
    }
}

.cityContainer {
    margin: $default-margin;
    margin-top: 6rem;
    width: 90%;

    .cityText {
        margin: $default-margin;
        width: 496px;
        @include fonts(SF Pro Text, normal, 600, 38px, 45px);
        color: $primary-color;
        text-align: center;

        @media only screen and (max-width: 600px) {
            font-size: 21px;
            line-height: 25px;
            width: 274px;
        }
    }
}

.cityCards {
    max-width: 1200px;
    width: 100%;
    margin: $default-margin;
    padding-top: 80px;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: 2px;
    display: flex;

    @media only screen and (max-width: 600px) {
        padding-top: 40px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .city-tableRow {
        display: table;
        justify-content: space-around;
        width: 100%;

        .city-tableCell {
            display: table-cell;
            padding-left: 15px;

            .city-tableCell:first-child {
                padding-left: 0px;
            }
        }
    }
}

.legalservicesContainerParent {
    padding: 2rem;
    border-radius: 7rem 0px 7rem 0px;
    @media only screen and (max-width: 600px) {
        border-radius: 4rem 0px 4rem 0px;
    }
}

.legalservicesContainer {
    height: auto;
    width: 30%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    margin: $default-margin;
    background-color: #ffffff;
    border-radius: 20px;
    justify-content: center;

    @media only screen and (max-width: 960px) {
        padding-left: 10px;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        padding-left: 0px;
        margin-top: 0px;

        margin-top: 0px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .legalservicesHeader {
        margin-top: 30px;
        @include fonts('Roboto', normal, 500, 1.3rem, 28px);
        color: $default-color;

        @media only screen and (max-width: 960px) {
            margin-top: 20px;
            font-size: 24px;
            line-height: 22px;
        }

        @media only screen and (max-width: 600px) {
            margin: 10px auto;
        }
    }

    .legalservicesInfo {
        margin-top: 1rem;
        @include fonts('Roboto', normal, 400, 1rem, 16px);
        color: #B5985E;
        line-height: 1.5rem;

        @media only screen and (max-width: 960px) {
            font-size: 17px;
            line-height: 21px;
            margin-top: 20px;
        }

        @media only screen and (max-width: 600px) {
            margin: 20px auto;
            text-align: center;
        }
    }

    .legalservicesInfo_1 {
        margin-top: 2rem;
        @include fonts('Roboto', normal, 400, 14px, 16px);
        color: #B5985E;
        line-height: 1.3rem;

        @media only screen and (max-width: 960px) {
            font-size: 17px;
            line-height: 21px;
            margin-top: 20px;
        }

        @media only screen and (max-width: 600px) {
            margin: 20px auto;
            text-align: center;
        }
    }

    .legalservicesButtonBox {

        @media only screen and (max-width: 960px) {
            margin-top: 15px;
        }

        @media only screen and (max-width: 600px) {
            margin: 32px auto;
        }

        .legalservicesButton {
            background: $secondary-color;
            border-radius: 5px;
            border: none;
            align-items: center;
            padding: 10px;
            color: #FFFFFF;
            @include fonts('Roboto', normal, 400, 17px, 21px);
            cursor: pointer;

            @media only screen and (max-width: 960px) {
                font-size: 16px;
                line-height: 23px;
                padding: 6px 10px;
            }
        }
    }

    .legalservicesImageBox {
        height: 267px;

        @media only screen and (max-width: 960px) {
            margin-left: 20px;
        }

        @media only screen and (max-width: 600px) {
            height: 192.04px;
        }
    }

    .legalservicesImage {
        height: 20rem;
        width: auto;
        position: absolute;
        margin-top: -14rem;
        right: 24%;


        @media only screen and (max-width: 600px) {
            float: unset;
            margin: 40px auto;
            display: none;
        }
    }
}

.contactusContainer {
    width: 90%;
    height: 482px;
    background: linear-gradient(180deg, #C3DFED 0%, #DFF0F7 100%);
    border-radius: 50px;
    @include custom-grids(repeat(2, 1fr), 219px 111px 152px, "h i""p i""b i");
    padding-left: 86px;
    margin: $default-margin;
    margin-top: 60px;
    overflow: hidden;

    @media only screen and (max-width: 960px) {
        width: 90%;
        padding-left: 26px;
    }

    @media only screen and (max-width: 600px) {
        @include custom-grids(repeat(1, 1fr), 65px 140px 82px 384.95px, "h""p""b""i");
        width: 360px;
        height: 671.95px;
        padding-left: 0px;
        background: linear-gradient(180deg, #DFF0F7 0%, #C4E0EB 47.26%);
        border-radius: 0px;
        margin-top: 40px;
    }

    .contactusHeader {
        grid-area: h;
        width: 379px;
        @include fonts('SF Pro Text', normal, 600, 54px, 64px);
        color: $primary-color;
        margin-top: 91px;
        text-align: left;

        @media only screen and (max-width: 960px) {
            width: auto;
            font-size: 28px;
            line-height: 38px;
            margin-top: 41px;
        }

        @media only screen and (max-width: 600px) {
            font-size: 21px;
            line-height: 25px;
            margin: 40px auto;
            text-align: center;
        }
    }

    .contactusInfo {
        grid-area: p;
        width: 470px;
        @include fonts('SF Pro Text', normal, 400, 22px, 36px);
        color: #585981;
        margin-top: 39px;

        @media only screen and (max-width: 960px) {
            width: auto;
            font-size: 18px;
            line-height: 28px;
            margin-top: -30px;
        }

        @media only screen and (max-width: 600px) {
            width: 320px;
            margin: 32px auto;
            text-align: center;
        }
    }

    .contactusButtonBox {
        grid-area: b;
        margin-top: 37px;

        @media only screen and (max-width: 960px) {
            margin-top: 57px;
        }

        @media only screen and (max-width: 600px) {
            margin: 12px auto;
        }

        .contactusButton {
            max-width: 213px;
            width: auto;
            padding: 7px 38px;
            background: $secondary-color;
            border-radius: 14px;
            border: none;
            @include fonts('SF Pro Text', normal, 600, 16px, 36px);
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #FFFFFF;
            cursor: pointer;

            @media only screen and (max-width: 960px) {
                font-size: 14px;
                line-height: 20px;
                padding: 7px 20px;
                height: 50px;
                min-width: 160px;
            }

            @media only screen and (max-width: 600px) {
                padding: 6px 39px;
                width: 320px;
                height: 50px;
            }
        }
    }

    .contactusImageBox {
        grid-area: i;
        border-radius: 50px;
        overflow: hidden;
        width: 710px;

        @media only screen and (max-width: 960px) {
            margin-left: 50px;
            width: 400px;
            display: flex;
            justify-content: center;
            border-radius: unset;
        }

        @media only screen and (max-width: 600px) {
            width: 100%;
            overflow: hidden;
            border-radius: 0px;
            margin-left: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .contactusImage {
            width: 710px;

            @media only screen and (max-width: 960px) {
                width: auto;
            }

            @media only screen and (max-width: 600px) {
                margin: $default-margin;
                margin-top: 73px;
                overflow-x: hidden;
                width: 100%;
                position: relative;
                right: 10px;
                transform: scale(1.3);
            }
        }
    }
}