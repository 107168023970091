.client-cards-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); // 3 columns
    gap: 20px; // Spacing between cards
    padding: 20px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr); // 2 columns for smaller screens
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr; // 1 column for very small screens
    }
}

.howitworksContainer {
    width: 90%;
    margin: 0px auto;
    margin-top: 5rem;
    display: flow;
}