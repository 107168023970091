@import url(//db.onlinewebfonts.com/c/1c45e28f8e86cc89876f003b953cc3e9?family=SF+Pro+Text);

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: none;
}

.navbarLogo1{
    width:52.54px;
    padding-right: 10px;
}

.navbarLogo1-small{
    width:12vw;
}

.navbarLogo2{
    width:12vw;
}

.navbarLogo2-small{
    width:97.18px;
    padding-bottom:8px;
}

#footerContainer {
    width: 100%;
    margin: 0;
    padding-bottom: 2rem;
    padding: 0;
    background: #000000;

    @media (max-width:600px) {
        margin-top: 0vh !important;
    }

    .firstBox {
        .firstBoxItem2 {
            .descr {
                width: 90%;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.9375rem;
                color: #ffffff;
                text-align: left;

                @media (max-width:900px) {
                    text-align: center;
                    width: 100%;
                }
            }
        }

        .firstBoxItem3 {
            .socialIcons {
                height: 1.375rem;
                cursor: pointer;
            }
            @media (max-width:600px) {
                display: flex;
                justify-content: center;
            }
        }

        .firstBoxItem4 {
            .copyRights {
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 400;
                font-size: 0.8125rem;
                line-height: 1rem;
                color: #ffffff;
            }
        }

        .footerLogo {
            width: 12vw;
        }
    }

    .secondAndThirdContainer {

        @media (max-width:900px) {
            display: flex;
            justify-content: center; 
         }
        .secondBox {
            .secondBoxTitle {
                padding-bottom: 1rem;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 2.25rem;
                color: #110229;
            }

            .secondBoxLinks {
                text-decoration: none;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 3.25rem;
                color: #110229;

            }
        }

        .thirdBox {
            .thirdBoxTitle {
                //width: 8.4375rem;
                padding-bottom: 1rem;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 600;
                font-size: 1.25rem;
                line-height: 2.25rem;
                color: #110229;

            }

            .thirdBoxLinks {
                text-decoration: none;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 400;
                font-size: 1.125rem;
                line-height: 3.25rem;
                color: #ffffff;

            }
        }
    }

    .fourthBox {
        padding-left: 4rem;

        @media (max-width:900px) {
            padding-left: 0;
        }

        .fourthBoxTitle {
            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 2.25rem;
            color: #ffffff;
        }

        .fourthBoxDescr {
            width: 80%;
            font-family: 'SF Pro Text';
            font-style: normal;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.4375rem;
            color: #ffffff;

            @media (max-width:900px) {
                text-align: center;
                width: 80%;
                margin: 0 auto;
            }
        }

        .emailContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFFFFF;
            border-radius: 0.9375rem;
            border: 0.125rem solid #D0D0E3;
            width: 90%;
            height: 54px;
            padding-top: 0;
            margin-top: 28px;

            &::placeholder,
            .fourthBoxEmail {
                width: 80%;
                padding-left: 15px;
                font-family: 'SF Pro Text';
                font-style: normal;
                font-weight: 600;
                font-size: 0.875rem;
                line-height: 1.4375rem;
                color: #8F90A6;
                border: none;

                &:focus {
                    outline: none;
                }
            }

            #emailSendIcon {
                cursor: pointer;
                color: #1DAEFF;
                width: 40px;
                padding-right: 10px;
                height: 100%;

                @media (max-width:900px) {}
            }
        }

    }
}

.firstBoxItem3_icons {
    @media (max-width:600px) {
        margin-bottom: 2rem;
    }
}

.firstBoxItem3phoneversion {
    margin-bottom: 3rem !important;
}